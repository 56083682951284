<template>
  <div class="container-page">
    <template>
      <v-card class="transparent elevation-0 card-pt-0">
        <v-card-title>
          <v-spacer />
          <v-flex
            xs12
            sm3>
            <v-select
              v-model="pagination.state"
              class="select-state pr-6 reset-padding-xs"
              item-value="value"
              item-text="label"
              label="Filtrar por status"
              :items="stateList"
              single-line />
          </v-flex>
          <v-flex
            xs12
            sm4>
            <v-text-field
              v-model="pagination.search"
              label="Buscar por boletos"
              :prepend-icon="$root.icons.layout.search"
              :append-icon="pagination.search.length > 0 ? 'close' : ''"
              single-line
              hide-details
              @click:append="clearSearch" />
          </v-flex>
        </v-card-title>
      </v-card>

      <loading
        v-if="loading"
        class="absolute-center" />

      <div
        v-if="isRender && loading === 0"
        class="px-0 py-0">
        <v-card
          v-if="bankSlips.length > 0"
          class="transparent elevation-0 card-pt-0">
          <v-data-table
            class="transparent table-clicked table-sticky"
            :options.sync="pagination"
            :headers="headers"
            :items="bankSlips"
            :search="pagination.search"
            :footer-props="footerProps"
            :server-items-length="pagination.serverItemsLength">
            <template
              v-slot:item="props"
              class="transparent">
              <tr tabindex="0">
                <td>{{ props.item.numDoc }}</td>
                <td>{{ props.item.payer.nome }}</td>
                <td>{{ props.item.parcel }}</td>
                <td>{{ $d(props.item.dueAt.toDate(), 'short') }}</td>
                <td>{{ $n(props.item.amount, 'currency') }}</td>
                <td>
                  <v-tooltip
                    top
                    content-class="table_trasparent">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on">
                        <v-icon color="grey lighten-1">
                          info
                        </v-icon>
                      </v-btn>
                    </template>

                    <v-simple-table dense>
                      <tbody>
                        <tr
                          v-for="(detail, index) in props.item.details"
                          :key="index">
                          <td>{{ $t('calculateParams.' + detail.type) }}</td>
                          <td>{{ $n(detail.amount, 'currency') }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-tooltip>

                  {{ $n(addition(props.item.details), 'currency') }}
                </td>
                <td>{{ $t('bankSlip.state.' + props.item.state) }}</td>
              </tr>
            </template>
            <template v-slot:pageText="props">
              Exibindo de {{ props.pageStart }} a {{ props.pageStop }} no total de {{ props.itemsLength }}
            </template>
            <empty-state
              slot="no-results"
              class="mt-2"
              size="75px"
              :icon="$root.icons.state.no_result">
              Sua busca por "{{ pagination.search }}" não possui resultados
            </empty-state>
          </v-data-table>
        </v-card>
        <div v-else>
          <empty-state
            v-if="pagination.state !== '' || pagination.search !== ''"
            size="75px"
            :icon="$root.icons.link.bankSlips"
            class="absolute-center">
            Até o momento, não há boletos com esse resultado
          </empty-state>
          <empty-state
            v-else
            size="75px"
            :icon="$root.icons.link.bankSlips"
            class="absolute-center">
            <template>
              Nenhum boleto emitido até o momento
            </template>
          </empty-state>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import Loading from '@/components/ui/Loading'
  import EmptyState from '@/components/ui/EmptyState'
  import { mapGetters } from 'vuex'

  export default {
    components: { Loading, EmptyState },
    data () {
      const rowsPerPageItems = [13, 25, 50, 100]

      return {
        search: '',
        stateList: [
          { label: 'Todos', value: '' },
          { label: 'Criado', value: 'created' },
          { label: 'Registrado', value: 'registered' },
          { label: 'Pagos', value: 'payed' }
        ],
        headers: [
          { text: 'Num Doc', value: 'name' },
          { text: 'Nome do campanha', value: 'name', sortable: false },
          { text: 'Parcela', value: 'proposal', sortable: false },
          { text: 'Vencimento', value: 'proposal', sortable: false },
          { text: 'Valor', value: 'proposal', sortable: false },
          { text: 'Acréscimos', value: 'addition', sortable: false },
          { text: 'Status', value: 'state', sortable: true }
        ],
        pagination: {
          state: sessionStorage.getItem('bankSlipPaginationState') || '',
          search: sessionStorage.getItem('bankSlipPaginationSearch') || '',
          sortBy: [sessionStorage.getItem('bankSlipPaginationSortBy') || 'num_doc'],
          sortDesc: [JSON.parse(sessionStorage.getItem('bankSlipPaginationDescending') || true)],
          page: parseInt(sessionStorage.getItem('bankSlipPaginationPage') || 1),
          rowsPerPage: parseInt(sessionStorage.getItem('bankSlipPaginationRowsPerPage') || 13),
          rowsPerPageItems: rowsPerPageItems,
          serverItemsLength: 1642
        },
        footerProps: {
          rowsPerPageItems: rowsPerPageItems,
          rowsPerPageText: 'Mostrar por página'
        }
      }
    },
    computed: {
      ...mapGetters({
        bankSlips: 'bankSlips/list',
        loading: 'bankSlips/listLoading',
        isRender: 'bankSlips/listRender'
      })
    },
    watch: {
      pagination: {
        handler (pagination) {
          console.log('pagination', pagination)
          this.$store.dispatch('bankSlips/getList', pagination)
          // sessionStorage.setItem('CasePaginationState', pagination.state)
          // sessionStorage.setItem('CasePaginationSearch', pagination.search)
          // sessionStorage.setItem('CasePaginationSortBy', pagination.sortBy)
          // sessionStorage.setItem('CasePaginationDescending', pagination.descending)
          // sessionStorage.setItem('CasePaginationRowsPerPage', pagination.rowsPerPage)
          // this.$store.dispatch('cases/getList', pagination)
          // TODO ajustar paginação
        },
        deep: true
      }
    },
    beforeMount () {
      this.$store.dispatch('bankSlips/getList', this.pagination)
    },
    methods: {
      clearSearch () {
        this.pagination.search = ''
      },
      addition (fees) {
        return fees.mapBy('amount').reduce((acc, fee) => acc += fee, 0)
      }
    }
  }
</script>

<style lang="sass">
  .height-auto
    height: auto!important
    overflow: initial!important
    width: 100%

  .v-align-center.v-icon
    vertical-align: bottom

  .select-state
    position: relative
    top: 12px

  .table_trasparent
    .theme--light.v-data-table
      background: transparent
      color: #fff
</style>
